* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  #-moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

.wc-sidePanel {
  padding: 0px;
}
.wc-sidePanel  .ms-Grid-col {}

@media only screen and (max-width: 768px) {
  body {
    /*width: fit-content;*/
  }
}
